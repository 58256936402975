import * as React from "react"

interface ContentSectionTitleProps {
  title: string
  description?: string
}

const ContentSectionTitle = ({
  title,
  description,
}: ContentSectionTitleProps) => (
  <div className="section-title fl-wrap">
    {title && <h3>{title}</h3>}
    {description && <p>{description}</p>}
  </div>
)

export default ContentSectionTitle
