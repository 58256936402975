import * as React from "react"

interface SectionDecoratorProps {
  type?: null | 1 | 2 | 3
}
const SectionDecorator = ({ type }: SectionDecoratorProps) => {
  let className = "col-wc_dec "
  switch (type) {
    case 2:
      className += "col-wc_dec2"
      break
    case 3:
      className += "col-wc_dec3"
      break
  }
  return <div className={className} />
}

export default SectionDecorator
