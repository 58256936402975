import * as React from "react"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import { graphql, Link } from "gatsby"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SyntaxHighlighter from "react-syntax-highlighter"
import { atelierForestDark } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../components/Shared/ContentWrapper"
import ContentSection from "../components/Shared/ContentSection"
import { useEffect } from "react"
import BlogNav from "../components/Blog/BlogNav"
import BlogPostMedia from "../components/Blog/BlogPostMediaPreview"

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        social {
          name
          icon
          url
        }
      }
    }
    aboutImage: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 80
          height: 80
          placeholder: BLURRED
          transformOptions: { cropFocus: ATTENTION }
          layout: FIXED
        )
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      createdAt(fromNow: true)
      excerpt {
        excerpt
      }
      categories {
        id
        name
        slug
      }
      tags {
        id
        name
        slug
      }
      featuredImage {
        file {
          url
        }
      }
      content {
        raw
      }
    }
  }
`

const BlogPost = ({ data, uri }) => {
  const postData = data.contentfulBlogPost
  const authorImageSrc = getSrc(data.aboutImage)

  let disqusConfig = {
    // url: `${config.siteUrl + location.pathname}`,
    url: `https://jerezbain.dev${uri}`,
    identifier: postData.id,
    title: postData.title,
  }

  const contentBlockOptions = {
    renderMark: {
      [MARKS.CODE]: text => {
        if (text.length < 50) {
          return <code>{text}</code>
        } else {
          return (
            <SyntaxHighlighter style={atelierForestDark} showLineNumbers>
              {text}
            </SyntaxHighlighter>
          )
        }
      },
    },
    renderNode: {
      // [BLOCKS.PARAGRAPH]: ({ content }) => {
      //   console.log({ content })
      //   return content[0].value
      // },
    },
  }

  useEffect(() => {
    // const codeblocks = window.$("p:has(code)")
    // codeblocks.addClass("codeblock")
    // console.log({ codeblocks })
  }, [])

  return (
    <Layout title={postData.title}>
      <SEO title={postData.title} />

      <ContentWrapper
        nav={
          <BlogNav
            backButton={
              <Link to="/blog" className="ajax back-to-home-btn">
                <span>Back to Blog</span>
              </Link>
            }
          />
        }
      >
        <ContentSection
          id="sec1"
          // description={`by Jerez Bain on ${postData.createdAt}`}
          decoratorPosition="top"
          separator
          sectionClasses="hidden-section bot-element text-left"
        >
          <div className="section-title fl-wrap post-title">
            <h3>{postData.title}</h3>
            <div className="post-header">
              <a href="#">{postData.createdAt}</a>
              <span>Category : </span>
              {postData.categories.map(category => (
                <Link
                  key={category.id}
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </div>

          <BlogPostMedia post={postData} />

          <div className="post-block fl-wrap">
            <div className="post-opt fl-wrap">
              <ul>
                <li>
                  <a href="#">
                    <i className="fal fa-user" /> Jerez Bain
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fal fa-comments-alt" />{" "}
                    <CommentCount config={disqusConfig} placeholder={"..."} />
                  </a>
                </li>
                {/*<li>
                  <span>
                    <i className="fal fa-eye" /> 123 view
                  </span>
                </li>*/}
              </ul>
            </div>
            {postData.content.raw
              ? documentToReactComponents(
                  JSON.parse(postData.content.raw),
                  contentBlockOptions
                )
              : null}
          </div>

          <div className="post-author">
            <div className="author-img text-center">
              {/*<Img fixed={data.aboutImage.childImageSharp.fixed} />*/}
              <img alt="" src={authorImageSrc} />
            </div>
            <div className="author-content">
              <h5>
                <a href="#">Jerez Bain</a>
              </h5>
              <p>
                I bring your ideas and designs to life. From small business
                websites to interactive web applications. My passion is building
                things and I put my best in every line of code, measurement, and
                decision.
              </p>
              <div className="author-social text-center">
                <ul>
                  {data.site.siteMetadata.social.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.url}
                        target="_blank"
                        title={item.name}
                        rel="noreferrer"
                      >
                        <i className={`fab fa-${item.icon}`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div id="comments" className="single-post-comm">
            <Disqus config={disqusConfig} />
          </div>
        </ContentSection>
      </ContentWrapper>
    </Layout>
  )
}

export default BlogPost
