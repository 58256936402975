import * as React from "react"
import ContentSectionTitle from "./ContentSectionTitle"
import ContentSeparator from "./ContentSeparator"
import SectionDecorator from "./SectionDecorator"
import { Container } from "react-bootstrap"

interface ContentSectionProps {
  id: string
  separator?: boolean
  title?: string
  description?: string
  number?: number
  children?: any
  sectionClasses?: any
  decoratorPosition?: null | "top" | "bottom"
  decoratorType?: null | 1 | 2 | 3
}
const ContentSection = ({
  id,
  separator = false,
  title,
  description,
  number,
  children,
  sectionClasses = `hidden-section scroll-con-sec bot-element`,
  decoratorPosition,
  decoratorType,
}: ContentSectionProps) => {
  const zeroPad = (num, places) => String(num).padStart(places, "0")
  return (
    <>
      <section id={id} className={sectionClasses}>
        {decoratorPosition === "top" && (
          <SectionDecorator type={decoratorType} />
        )}
        <Container>
          {title && (
            <ContentSectionTitle title={title} description={description} />
          )}
          {children}
          {!isNaN(number) && (
            <div className="sec-number">{zeroPad(number, 2)}.</div>
          )}
        </Container>
        {decoratorPosition === "bottom" && (
          <SectionDecorator type={decoratorType || 2} />
        )}
      </section>
      {separator && <ContentSeparator />}
    </>
  )
}

export default ContentSection
